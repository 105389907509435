<template>
  <div class="business-detail-page">
    <!--导航条-->
    <navigation-bar pageName="业务领域" businsessName="详情"></navigation-bar>
    <div class="category-item-wrap">
      <div class="category-item-content">
        <img :src="detail.contentHeadImgUrl" class="category-item-intro-icon" />
        <div v-html="detail.description" class="category-item-intro"></div>
        <div v-html="detail.content" class="category-item-intro"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavigationBar from '@/components/navigation-bar.vue'

  import {
    getNewsDetail
  } from '@/api/news.js'

  export default {
    components: {
      NavigationBar
    },
    data() {
      return {
        detail:{extendsFields:{}}
      }
    },
    created() {
      this.getDetail(this.$route.query.detailId)
    },
    computed: {

    },
    methods: {
      getDetail(detailId) {
        getNewsDetail(detailId).then(data => {
          this.detail = data.data
        })
      }
    }
  }
</script>

<style lang="scss">
  .business-detail-page {
    padding-bottom: 30px;
    .category-item-wrap {
      padding: 20px 0px;
    }

    .category-item-content {
      padding: 0px 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .category-item-intro-icon {
        width: 95px;
      }

      .category-item-intro {
        box-sizing: border-box;
        margin-top: 15px;
        font-size: 13px;
        line-height: 18px;
        color: #525252;
      }

      .lean-more-link {
        margin-top: 5px;
        line-height: 40px;
        font-size: 12px;
        color: #f36314;
      }

    }

  }
</style>
