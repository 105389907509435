<template>
  <div class="navigation-bar-wrap">
    <div class="navigation-bar">首页<span class="arrow-space">&gt;</span>{{pageName}} <span class="arrow-space" v-if="businsessName">&gt;</span>{{businsessName}}</div>
  </div>
</template>

<script>
  export default {
    props:{
      pageName:String,
      businsessName:String
    }
  }
</script>

<style lang="scss">
  .navigation-bar-wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 54px;
    padding-right: 12px;
    
    .arrow-space{
      padding: 0px 2px;
    }
    
  }
  .navigation-bar{
    display: flex;
    background-image: url(../image/home/navigation-location.png);
    background-repeat: no-repeat;
    background-size: 15px;
    height: 18px;
    padding-left: 20px;
    font-size: 11px;
    align-items:flex-end ;
  }
</style>
